import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {observable, Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {IAPIResponse} from "../Models/IAPIResponse";
import {IUser} from "../Models/users/IUser";

@Injectable({
  providedIn: 'root'
})
export class UserRolesGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let profile = localStorage.getItem('profile');
      if(!profile) {
        return this.getMyProfile().pipe(
          map(x => {
            localStorage.setItem('profile', JSON.stringify(x.result));
            return this.userShouldResetPassword(x.result);
          })
        );
      }else{

        return this.userShouldResetPassword(JSON.parse(profile));
      }
  }

  userShouldResetPassword(profile: any): boolean{
    let changed = profile.passwordChanged;
    if(!changed)
      this.router.navigate(['/password/change']);
    return changed;
  }

  constructor(private http: HttpClient, private router: Router) {}
  getMyProfile(): Observable<any>{
    return this.http.get<IAPIResponse<IUser>>(`${environment.baseUrl}users/my-user-details`);
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginResponse } from './Models/Login';
import { LoginService } from './Services/login/login.service';
import { PrimeNGConfig } from 'primeng/api';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  user: LoginResponse = null as any;
  topbarTheme = 'blue';

  menuTheme = 'light';

  layoutMode = 'light';

  menuMode = 'static';

  inlineMenuPosition = 'bottom';

  inputStyle = 'filled';

  ripple = true;

   isRTL = false;
  constructor(private router: Router, private loginService: LoginService, private primengConfig: PrimeNGConfig) {

    this.loginService.user.subscribe(x => this.user = x);
  }


  ngOnInit()
  {
    this.primengConfig.ripple = true;
  }

}

<!-- <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="user">
  <div class="navbar-nav">
      <a class="nav-item nav-link" routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
      <a class="nav-item nav-link" (click)="logout()">Logout</a>
  </div>
</nav>

<div class="app-container" [ngClass]="{ 'bg-light': user }">
  <router-outlet></router-outlet>
</div> -->
<router-outlet></router-outlet>

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../Services/login/login.service';
import {NotificationMessageService} from "../Services/Layout/notification-message.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: LoginService, private notificationMessageService: NotificationMessageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authService.logout();
      }else if (err.status == 400){
        if(err.error.errors)
          err.error.errors.forEach((item: any) => {
            this.notificationMessageService.send( item, true);
          })
        else
          this.notificationMessageService.send( err.error.message, true);
          return EMPTY;
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
  }))
}
}

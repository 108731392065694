import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserBehaviourService {

  constructor() { }
  private userProfile: any = null;

  getUserId() {
    let data: any = this.getUserProfile();
    return data.id;
  }
  getUserType() {
    let data: any = this.getUserProfile();
    return data.type;
  }
  getUserProfileFromLocale() {
    return JSON.parse(localStorage.getItem('profile')??'{}');
  }
  getUserProfile() {
    if(!this.userProfile){
      this.userProfile = this.getUserProfileFromLocale();
    }
    return this.userProfile;
  }
  setPasswordIsUpdated() {
    if(!this.userProfile){
      this.userProfile = this.getUserProfileFromLocale();
    }
    this.userProfile.passwordChanged = true;
    localStorage.setItem('profile', JSON.stringify(this.userProfile));
  }

  userHasRole(role: string) {
    if(role == undefined)
      return true;
    if(this.userProfile == null || !this.userProfile.hasOwnProperty('roles')){
      this.userProfile = this.getUserProfileFromLocale();
      if(!this.userProfile.hasOwnProperty('roles'))
        return false;
    }
    if(this.userProfile.roles.includes('ROLE_SUPER_ADMIN'))
      return true;
    return this.userProfile.roles.includes(role);
  }
  clearUserProfile() {
    this.userProfile =null;
  }

}

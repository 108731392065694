import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './Components/Layout/page-not-found/page-not-found.component';
import { ErrorPageComponent } from './Components/Layout/error-page/error-page.component';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './Helpers/jwt.interceptor';
import { ErrorInterceptor } from './Helpers/error.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CommonModule, DatePipe} from '@angular/common';
import { ButtonModule } from 'primeng/button';
import {ImageModule} from 'primeng/image';
import { ForgotPasswordComponent } from './Components/forgot-password/forgot-password/forgot-password.component';
import { ForgotPasswordCompleteComponent } from './Components/forgot-password/forgot-password-complete/forgot-password-complete.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ErrorPageComponent,
    ForgotPasswordComponent,
    ForgotPasswordCompleteComponent,
  ],
  imports: [
    ImageModule,
     BrowserModule,
    CommonModule,
     AppRoutingModule,
     HttpClientModule,
     BrowserAnimationsModule,
     ButtonModule,

    ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './Components/home/home.component';
import {DashboardComponent} from './Components/Layout/dashboard/dashboard.component';
import {ErrorPageComponent} from './Components/Layout/error-page/error-page.component';
import {MainComponent} from './Components/Layout/main/main.component';
import {PageNotFoundComponent} from './Components/Layout/page-not-found/page-not-found.component';
import {AuthGuard} from './Helpers/auth.guard';
import {NoDataFoundComponent} from './Components/Layout/no-data-found/no-data-found.component'
import {EntitiesModule} from "./Components/entities/entities.module";
import {UserRolesGuard} from "./Helpers/user-roles.guard";

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard, UserRolesGuard],
    children: [

      {
        path: '', loadChildren: () => import('./Components/home/home.module')
          .then(m => m.HomeModule), canActivate: [AuthGuard]
      },
      {
        path: 'home', loadChildren: () => import('./Components/home/home.module')
          .then(m => m.HomeModule), canActivate: [AuthGuard, UserRolesGuard]
      }
    ]
  },
  {
    path: 'profile', loadChildren: () => import('./Components/users/users.module')
      .then(m => m.UsersModule), canActivate: [AuthGuard]
  },
  {
    path: 'password', loadChildren: () => import('./Components/change-password/change-password.module')
      .then(m => m.ChangePasswordModule), canActivate: [AuthGuard]
  },
  {
    path: 'announcements', loadChildren: () => import('./Components/announcements/announcements.module')
      .then(m => m.AnnouncementsModule), canActivate: [AuthGuard, UserRolesGuard]
  },
  {
    path: 'feedbacks', loadChildren: () => import('./Components/feedbacks/feedbacks.module')
      .then(m => m.FeedbacksModule), canActivate: [AuthGuard, UserRolesGuard]
  },
  {
    path: 'help-documents', loadChildren: () => import('./Components/help-document/help-document.module')
      .then(m => m.helpDocumentModule), canActivate: [AuthGuard, UserRolesGuard]
  },
  {
    path: 'role-templates', loadChildren: () => import('./Components/role-templates/role-templates.module')
      .then(m => m.RoleTemplatesModule), canActivate: [AuthGuard, UserRolesGuard]
  },
  {
    path: 'entities', loadChildren: () => import('./Components/entities/entities.module')
      .then(m => m.EntitiesModule), canActivate: [AuthGuard, UserRolesGuard]
  },
  {
    path: 'users', loadChildren: () => import('./Components/users/users.module')
      .then(m => m.UsersModule), canActivate: [AuthGuard, UserRolesGuard]
  },
  {
    path: 'login', loadChildren: () => import('./Components/accounts/account.module')
      .then(m => m.AccountModule)
  },
  {
    path: 'technical-support', loadChildren: () => import('./Components/technical-support/technical-support.module')
      .then(m => m.TechnicalSupportModule), canActivate: [AuthGuard, UserRolesGuard]
  },
  {
    path: 'reported-issue', loadChildren: () => import('./Components/reported-issues/reported-issues.module')
      .then(m => m.ReportedIssuesModule), canActivate: [AuthGuard, UserRolesGuard]
  },
  {
    path: 'push-notifications', loadChildren: () => import('./Components/push-notifications/push-notifications.module')
      .then(m => m.PushNotificationsModule), canActivate: [AuthGuard, UserRolesGuard]
  },
  {
    path: 'contact-support', loadChildren: () => import('./Components/contact-support/contact-support.module')
      .then(m => m.ContactSupportModule)
  },
  {path: 'nodatafound', component: NoDataFoundComponent},
  {path: 'notfound', component: PageNotFoundComponent},
  {path: 'error', component: ErrorPageComponent},
  {path: '**', redirectTo: '/notfound'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password-complete',
  templateUrl: './forgot-password-complete.component.html',
  styleUrls: ['./forgot-password-complete.component.css']
})
export class ForgotPasswordCompleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationMessageService {

  private messageSubject = new Subject<any>();

  constructor() { }

  send(message: string, isError: boolean = false){
    this.messageSubject.next({
      key: 'tst',
      severity: (isError)?'error':'success',
      summary: (isError)?'Error': 'Success Message',
      detail: message
    });
  }

  getSubject(): Observable<any> {
    return this.messageSubject.asObservable();
  }

}


import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {LoginResponse} from 'src/app/Models/Login';
import {environment} from '../../../environments/environment';
import {UserBehaviourService} from "../users/user-behaviour.service";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private userSubject: BehaviorSubject<LoginResponse>;
  public user: Observable<LoginResponse>;

  constructor(private userBehaviour: UserBehaviourService, private http: HttpClient, private router: Router) {
    this.userSubject = new BehaviorSubject<LoginResponse>(JSON.parse(localStorage.getItem('user') as any));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): LoginResponse {
    return this.userSubject.value;
  }

  login(username: any, password: any) {
    let grant_type = 'password';
    let body = `grant_type=${grant_type}&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;

    return this.http.post<LoginResponse>(`${environment.baseUrl}oauth/token`, body,
      {
        //observe: 'response',
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': environment.oAuthHeader
        })
      }).pipe(map(user => {
      // store  duseretails and jwt token in local storage to keep user logged in between page refreshes
      if (user != null) {
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
      }

      return user;
    }));
  }

  logout() {
    let user1: LoginResponse = null as any;
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    localStorage.removeItem('profile');
    this.userBehaviour.clearUserProfile();
    this.userSubject.next(user1);
    this.router.navigate(['/login']);
  }

  getToken() {
    let loggedUser: LoginResponse = null as any;
    this.user.subscribe(x => loggedUser = x);

    return "Bearer " + loggedUser.access_token;
  }
}
